import { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { MultiSelect } from "primereact/multiselect";
import { toast } from "react-toastify";
import { Calendar } from "primereact/calendar";
import { AiOutlineCalendar } from "react-icons/ai";

import {
  DateSelectionToDjangoFormat,
  DateTimeToDate,
} from "../../../../helpers";
import { complianceAPI } from "../../../../Api/CSPM/api";
import HandleResponseError from "../../../../utils/error";

export default function AddSecurityAwareness({
  visible,
  onHide,
  currentDetails,
}) {
  const [data, setData] = useState({});
  const [error, setError] = useState({});

  const [employee, setEmployee] = useState([]);

  const [openLaunchDate, setOpenLaunchDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);

  const handleCreate = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");
    if (currentDetails) {
      complianceAPI
        .patch(`/security-awareness/${currentDetails.id}/`, data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Audit updated successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    } else {
      complianceAPI
        .post("/security-awareness/", data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Audit added successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    }
  };

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployee(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getEmployee();
  }, []);

  useEffect(() => {
    if (currentDetails) setData(currentDetails);
  }, [currentDetails]);

  const cleanClose = () => {
    onHide();
    setData({});
    setError({});
  };

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={cleanClose}
      className={`w-full md:w-20rem lg:w-30rem`}
    >
      <p className="font-bold text-2xl">{`${
        currentDetails ? "Edit" : "Add"
      } Security Awareness Campaign`}</p>
      <p className="text-sm py-1">{`${
        currentDetails ? "Edit" : "Create a new"
      } Security Awareness Campaign`}</p>
      <div className="flex flex-wrap my-2 gap-2">
        <form onSubmit={(e) => handleCreate(e)} className="w-full">
          <div className="w-full">
            <label className="block text-gray-500 text-sm ">Name</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Enter name"
              value={data.name}
              required
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
                setError({ ...error, name: false });
              }}
            />
            {error.name && <p className="text-red-500 text-sm">{error.name}</p>}
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Description</label>
            <textarea
              row={10}
              className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
              placeholder="Enter description"
              value={data.description}
              required
              onChange={(e) => {
                setData({ ...data, description: e.target.value });
                setError({ ...error, description: false });
              }}
            />
            {error.description && (
              <p className="text-red-500 text-sm">{error.description}</p>
            )}
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Link</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="link"
              placeholder="Link"
              value={data.link}
              required
              onChange={(e) => {
                setData({ ...data, link: e.target.value });
                setError({ ...error, link: false });
              }}
            />
            {error.link && <p className="text-red-500 text-sm">{error.link}</p>}
          </div>

          <div className="w-full">
            <label className="text-sm font-normal text-[#686868]">
              Launch Date
            </label>
            <div className="relative">
              <input
                value={DateTimeToDate(data.launch_date)}
                placeholder="___ __, ____"
                className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
              />
              <div
                className="absolute cursor-pointer top-[12px] right-[12px]"
                onClick={() => setOpenLaunchDate(!openLaunchDate)}
              >
                <AiOutlineCalendar />
              </div>
            </div>
            {openLaunchDate && (
              <div className="mx-auto">
                <div className="">
                  <Calendar
                    value={new Date(data.launch_date)}
                    onChange={(e) => {
                      setData({
                        ...data,
                        launch_date: DateSelectionToDjangoFormat(e.value),
                      });
                      setOpenLaunchDate(false);
                    }}
                    inline
                    style={{ width: "360px" }}
                    maxDate={new Date(data?.end_date)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="w-full">
            <label className="text-sm font-normal text-[#686868]">
              End Date
            </label>
            <div className="relative">
              <input
                value={DateTimeToDate(data.end_date)}
                placeholder="___ __, ____"
                className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
              />
              <div
                className="absolute cursor-pointer top-[12px] right-[12px]"
                onClick={() => setOpenEndDate(!openEndDate)}
              >
                <AiOutlineCalendar />
              </div>
            </div>
            {openEndDate && (
              <div className="mx-auto">
                <div className="">
                  <Calendar
                    value={new Date(data.end_date)}
                    onChange={(e) => {
                      setData({
                        ...data,
                        end_date: DateSelectionToDjangoFormat(e.value),
                      });
                      setOpenEndDate(false);
                    }}
                    inline
                    style={{ width: "360px" }}
                    minDate={new Date(data?.launch_date)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="mt-3">
            <label className="block text-gray-500 text-sm">Employees</label>
            <MultiSelect
              className="w-full"
              optionLabel="email"
              optionValue="id"
              filter
              value={data.employees}
              options={employee}
              placeholder="Select Employees"
              onChange={(e) => setData({ ...data, employees: e.value })}
            />
          </div>
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
            >
              {currentDetails ? "Save" : "Add"}
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={cleanClose}
              type="reset"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </Sidebar>
  );
}
