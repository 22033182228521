import { Sidebar } from "primereact/sidebar";
import { useState } from "react";
import { toast } from "react-toastify";
import { PiCertificate } from "react-icons/pi";

import { ptaasAPI } from "../../../../Api/PTaaS/api";
import handleDownload from "../../../../utils/fileDownload";
import { handleSubmitButtonPress } from "../../../../utils/utils";

export function GenerateCertificateSideBar({
  showCertificate,
  setShowCertificate,
  scanId = showCertificate,
}) {
  const [data, setData] = useState({ scanId: scanId });

  const cleanClose = () => {
    setData(null);
    setShowCertificate(false);
  };

  const handleCreate = (e) => {
    handleSubmitButtonPress(e);
    ptaasAPI
      .post(`/certificate/${scanId}/`, data)
      .then((res) => {
        if (res.status === "success") {
          toast.success("Certificate downloading...");
          handleDownload(res.data);
          handleSubmitButtonPress(e);
          cleanClose();
        } else {
          toast.error(res.message);
          handleSubmitButtonPress(e);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        handleSubmitButtonPress(e);
      });
  };
  return (
    <Sidebar
      visible={showCertificate}
      position="right"
      onHide={() => setShowCertificate(false)}
    >
      <p className="text-2xl font-bold mb-2">Generate Certificate</p>
      <form onSubmit={(e) => handleCreate(e)} className="w-full">
        <div className="w-full">
          <label className="block text-gray-500 text-sm">Project Name</label>
          <input
            className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
            type="text"
            placeholder="Enter Project Name"
            value={data?.name}
            required
            onChange={(e) => {
              setData({ ...data, name: e.target.value });
            }}
          />
        </div>
        <div className="flex w-full mt-9">
          <button
            className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
            type="submit"
            id="submit"
          >
            Generate
          </button>
          <button
            className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
            onClick={cleanClose}
            type="reset"
          >
            Back
          </button>
        </div>
      </form>
    </Sidebar>
  );
}

export default function GenerateCertificate({ scanId }) {
  const [showCertificate, setShowCertificate] = useState(false);

  return (
    <>
      <button
        className="flex items-center gap-[6px] text-[#074EE8] text-base ml-4 px-3"
        onClick={() => setShowCertificate(true)}
      >
        Generate Certificate
        <PiCertificate />
      </button>
      <GenerateCertificateSideBar
        showCertificate={showCertificate}
        setShowCertificate={setShowCertificate}
        scanId={scanId}
      />
    </>
  );
}
